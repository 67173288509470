



















import { Action } from "vuex-class";
import { Component, Vue, Ref } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";

@Component({
  components: {
    FormBase,
    FormInput,
    FormError,
    FormSubmit,
  },
})
export default class PagePasswordForgot extends Vue {
  @Action("auth/passwordForgot") passwordForgot!: (payload: PasswordForgotPayload) => Promise<{}>;

  @Ref() form!: FormClass;

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  payload: PasswordForgotPayload = {
    email: "",
  };

  completed = false;

  private submit() {
    this.passwordForgot(this.payload)
      .then(() => (this.completed = true))
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }
}
